<div class="page-header">
  <h1>{{ "sponsoredFamilies" | i18n }}</h1>
</div>
<ng-container *ngIf="loading">
  <i class="bwi bwi-spinner bwi-spin text-muted" title="{{ 'loading' | i18n }}"></i>
  <span class="sr-only">{{ "loading" | i18n }}</span>
</ng-container>
<ng-container *ngIf="!loading">
  <p>
    {{ "sponsoredFamiliesEligible" | i18n }}
  </p>
  <div>
    {{ "sponsoredFamiliesInclude" | i18n }}:
    <ul class="inset-list">
      <li>{{ "sponsoredFamiliesPremiumAccess" | i18n }}</li>
      <li>{{ "sponsoredFamiliesSharedCollections" | i18n }}</li>
    </ul>
  </div>
  <form
    #form
    (ngSubmit)="submit()"
    [appApiAction]="formPromise"
    [formGroup]="sponsorshipForm"
    ngNativeValidate
    *ngIf="anyOrgsAvailable"
  >
    <div class="form-group col-7">
      <label for="availableSponsorshipOrg">{{ "familiesSponsoringOrgSelect" | i18n }}</label>
      <select
        id="availableSponsorshipOrg"
        name="Available Sponsorship Organization"
        formControlName="selectedSponsorshipOrgId"
        class="form-control"
        required
      >
        <option disabled="true" value="">-- {{ "select" | i18n }} --</option>
        <option *ngFor="let o of availableSponsorshipOrgs" [ngValue]="o.id">{{ o.name }}</option>
      </select>
    </div>
    <div class="form-group col-7">
      <label for="sponsorshipEmail">{{ "sponsoredFamiliesEmail" | i18n }}:</label>
      <input
        id="sponsorshipEmail"
        class="form-control"
        inputmode="email"
        formControlName="sponsorshipEmail"
        name="sponsorshipEmail"
        required
        [attr.aria-invalid]="sponsorshipEmailControl.invalid"
      />
      <small
        aria-errormessage="sponsorshipEmail"
        *ngIf="sponsorshipEmailControl.errors?.notAllowedValue"
        class="error-inline"
        role="alert"
      >
        <i class="bwi bwi-error" aria-hidden="true"></i>
        {{ "cannotSponsorSelf" | i18n }}
      </small>
      <small
        aria-errormessage="sponsorshipEmail"
        *ngIf="sponsorshipEmailControl.errors?.email"
        class="error-inline"
        role="alert"
      >
        <i class="bwi bwi-error" aria-hidden="true"></i>
        {{ "invalidEmail" | i18n }}
      </small>
    </div>
    <div class="form-group col-7">
      <button class="btn btn-primary btn-submit mt-2" type="submit" [disabled]="form.loading">
        <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
        <span>{{ "redeem" | i18n }}</span>
      </button>
    </div>
  </form>
  <ng-container *ngIf="anyActiveSponsorships">
    <div class="border-bottom">
      <table class="table table-hover table-list">
        <thead>
          <tr>
            <th>{{ "recipient" | i18n }}</th>
            <th>{{ "sponsoringOrg" | i18n }}</th>
            <th>{{ "status" | i18n }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let o of activeSponsorshipOrgs">
            <tr
              sponsoring-org-row
              [sponsoringOrg]="o"
              [isSelfHosted]="isSelfHosted"
              (sponsorshipRemoved)="load(true)"
            ></tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <small>{{ "sponsoredFamiliesLeaveCopy" | i18n }}</small>
  </ng-container>
</ng-container>
