<form #form class="card" (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate>
  <div class="card-body">
    <button type="button" class="close" appA11yTitle="{{ 'cancel' | i18n }}" (click)="cancel()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h3 class="card-body-header">{{ "downloadLicense" | i18n }}</h3>
    <div class="row">
      <div class="form-group col-6">
        <div class="d-flex">
          <label for="installationId">{{ "enterInstallationId" | i18n }}</label>
          <a
            class="ml-auto"
            target="_blank"
            rel="noopener"
            appA11yTitle="{{ 'learnMore' | i18n }}"
            href="https://bitwarden.com/help/licensing-on-premise/#organization-account-sharing"
          >
            <i class="bwi bwi-question-circle" aria-hidden="true"></i>
          </a>
        </div>
        <input
          id="installationId"
          class="form-control"
          type="text"
          name="InstallationId"
          [(ngModel)]="installationId"
          required
        />
      </div>
    </div>
    <button type="submit" class="btn btn-primary btn-submit" [disabled]="form.loading">
      <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
      <span>{{ "submit" | i18n }}</span>
    </button>
    <button type="button" class="btn btn-outline-secondary" (click)="cancel()">
      {{ "cancel" | i18n }}
    </button>
  </div>
</form>
