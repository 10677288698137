<div class="card card-org-plans">
  <div class="card-body">
    <button type="button" class="close" appA11yTitle="{{ 'cancel' | i18n }}" (click)="cancel()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h2 class="card-body-header">{{ "changeBillingPlan" | i18n }}</h2>
    <p class="mb-0">{{ "changeBillingPlanUpgrade" | i18n }}</p>
    <app-organization-plans
      [showFree]="false"
      [showCancel]="true"
      [plan]="defaultUpgradePlan"
      [product]="defaultUpgradeProduct"
      [organizationId]="organizationId"
      (onCanceled)="cancel()"
    >
    </app-organization-plans>
  </div>
</div>
